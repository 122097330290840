'strict mode';

import $ from 'jquery';
import slick from 'slick-slider';

class Main {
	constructor () {
		this.initSlider();
		this.toggleBurger();
		this.resizeWindow();
		this.initTechno();
	}

	initSlider = () => {
		$('.slider').slick({
			dots: true,
			arrows: false,
			infinite: false,
			autoplay: false
		});
	}

	toggleBurger = () => {
		$('.header__btn').click(({ currentTarget }) => {
			$(currentTarget).toggleClass('header__btn--active');
			$('.navigation').slideToggle();
		});
	}

	resizeWindow = () => {
		$(window).resize(() => {
			if ($(window).width() > 767) {
				$('.navigation').removeAttr('style');
				$('.header__btn').removeClass('header__btn--active');
			}

			this.initTechno();
		});

		if ($(window).width() < 767) {
			this.toggleBurger();
		} else {
			$('.navigation').removeAttr('style');
			$('.header__btn').removeClass('header__btn--active');
		}
	}

	initTechno = () => {
		console.log('Hree');
		if ($(window).width() < 767 && !$('.technologies__wrap').hasClass('slick-initialized')) {
			$('.technologies__wrap').slick({
				dots: false,
				arrows: true,
				infinite: true,
				autoplay: false,
				centerMode: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				prevArrow: $('.technologies__btn--prev'),
				nextArrow: $('.technologies__btn--next')
			});
		} else if ($(window).width() >= 767 && $('.technologies__wrap').hasClass('slick-initialized')) {
			$('.technologies__wrap').slick('unslick');
		}
	}
}

$(document).ready(() => {
	new Main();
});
